<template>
    <div class="expressSiteWarp">
        <div class="headerBg">
            <div class="iframeDiv">
                <iframe v-if="iframeUrl"  style="border: none" class="lineIframe" scrolling="no" :src="iframeUrl" :width="iframeWidth" :height=iframeHeight allowtransparency="">
                </iframe>
                <iframe v-if="iframeUrl1" style="border: none" class="lineIframe" scrolling="no" :src="iframeUrl1" :width="0" :height=0 allowtransparency="">
                </iframe>
            </div>


        </div>

        <div class="listDiv">

            <van-list :error.sync="error"
                      error-text="请求失败，点击重新加载"
                      v-model="loading"
                      :finished="finished"
                      finished-text="没有更多了"
                      @load="onLoad"
                      ref="scrollNews">
                <li class="itemli" v-for="(item,index) in infoList"
                     :key="index">
                    <div class="siteInfo">
                        <label>{{item.stationName}}</label>
                        <p>{{item.areaName |provinceFormmate}} <i></i> {{item.areaName |cityFormmate }}</p>
                    </div>
                    <p class="addr">
                        <i></i>
                        {{item.address}}
                    </p>
                    <p class="manage">
                        <i></i>
                        负责人: {{item.userName}}
                    </p>

                </li>
            </van-list>
        </div>


    </div>
    
</template>

<script>
    import {getExpressListForH5, getHomeInfo, getMetabaseUrl} from "../../utils/api";
    import {uwStatisticAction} from "../../utils/util";

    export default {
        name: "expressSiteDetail",
        data(){
            return{
                iframeUrl:'',
                iframeUrl1:'',
                iframeWidth:0,
                iframeHeight:0,
                loading: false,
                pageIndex: 1,
                pageSize: '10',
                finished: false,
                infoList:[],
                error :false,
                addr:''
            }
        },
        filters:{
            cityFormmate(val){
                let json = JSON.parse(val)
                return json.city
            },
            provinceFormmate(val){
                let json = JSON.parse(val)
                return json.province
            }
        },
       created() {
            this.initMetabase(10)

       },
        mounted() {
            let self = this
            setTimeout(function () {
                self.initMetabase(15)
            },500)
            uwStatisticAction('/expressSiteDetail','村口快递')

        },
        methods:{
            initMetabase(time){
                this.iframeWidth = document.body.clientWidth - 30
                this.iframeHeight = (this.iframeWidth * 28)/32.5
                console.log("计算高度")
                getHomeInfo().then(res=>{
                    if ( res.data.homePage.expressChart){
                        var jwt = require("jsonwebtoken");
                        var METABASE_SITE_URL = res.data.homePage.expressChart[0].reqUrl
                        var METABASE_SECRET_KEY = res.data.homePage.expressChart[0].spare1;
                        var dashboard = parseInt(res.data.homePage.expressChart[0].spare2);
                        // var METABASE_SECRET_KEY = "9077f7327169a9298c29a0a1a4e775fa3a7bb4066ef2302440927bf0c924d8ec";
                        var payload ={}
                        if(window.location.host.indexOf('124.70.167.116')>-1){
                            payload = {
                                resource: { dashboard: dashboard },
                                params: {},
                                exp: Math.round(Date.now() / 1000) + (time * 60) // 10 minute expiration
                            };
                        }else {
                            payload = {
                                resource: { dashboard: dashboard },
                                params: {},
                                exp: Math.round(Date.now() / 1000) + (time * 60) // 10 minute expiration
                            };
                        }
                        var token = jwt.sign(payload, METABASE_SECRET_KEY);
                        if (time === 10){
                            this.iframeUrl1 = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true";
                        console.log("隐藏的iframe：",this.iframeUrl1)
                        }else {
                            this.iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true";
                            console.log("显示的iframe：",this.iframeUrl)
                        }
                    }

                })
            },
            onLoad () {
                this.getData()
            },
            getData(){
                let p = {
                    'pageIndex': this.pageIndex,
                    'pageSize': this.pageSize
                }
                getExpressListForH5(p).then(res=>{
                    if (res.code == 200) {
                        if (res.data.businessCode && res.data.businessCode === 200) {
                            this.finished = !res.data.data.hasNextPage;
                            this.loading = false

                            if (this.pageIndex == 1) {
                                this.infoList = res.data.data.list;
                            } else {
                                this.infoList = this.infoList.concat(res.data.data.list);
                            }
                            if (!this.finished) {
                                this.pageIndex++;
                            }
                        } else {
                            this.error = true;
                        }
                    } else {
                        this.error = true;
                    }
                }).catch(()=>{
                    this.error = true;
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .headerBg{
        /*height:310px ;*/
        width: 100%;
        background: url("../../assets/img/expressSite/headerBg.png");
        background-size: cover;
        position: relative;
        /*margin-bottom: 70px;*/
        padding-top: 40px;

    }
    .iframeDiv{
        background: white;
        width: calc(100% - 20px);
        border-radius: 16px;
        padding: 20px 5px;
        margin-left: 10px;
        min-height: 200px;
    }
    .lineIframe{

    }
    .listDiv{
        padding: 15px;
    }
    .itemli{
        list-style-type: none;
        background: #FFFFFF;
        box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        padding: 25px 20px ;
        margin-bottom: 12px;

        .siteInfo{
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            label{
                font-size: 17px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #1E1F20;

            }
            p{

                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #1E1F20;
                i{
                    display: inline-block;
                    vertical-align: middle;
                    width: 5px;
                    height: 5px;
                    background: #333333;
                    border-radius: 50%;
                    margin: 0 1px;
                }

            }

        }
        i{
            margin-right: 10px;
            display: inline-block;
            vertical-align: top;

            margin-right:10px ;
            width: 14px;
            height: 16px;
        }
        .addr{

            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #787979;
            margin-bottom: 12px;
            i{
                background: url("../../assets/img/expressSite/addr.png");
                background-size: 100% 100%;
            }
        }
        .manage{
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #787979;
            i{
                background: url("../../assets/img/expressSite/zhanzhang.png");
                background-size: 100% 100%;
            }
        }
    }

</style>